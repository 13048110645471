import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";

import { AuthContext } from "../../context/Auth/AuthContext";
import CheckoutModal from "../../components/CheckoutPage/CheckoutPage";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const _formatDate = (date) => {
  const now = new Date();
  const past = new Date(date);
  const diff = Math.abs(now.getTime() - past.getTime());
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return days;
}

const Contacts = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const subscribed = user?.company?.subscribed

  const [loading,] = useState(false);
  const [, setPageNumber] = useState(1);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [hasMore,] = useState(false);
  const [portalUrl, setPortalUrl] = useState(null);
  const trialExpirationDate = user?.company?.dueDate;
  const formattedDate = _formatDate(trialExpirationDate);
  const totalConnection = user?.company?.totalConnection
  const totalUsers = user?.company?.totalUsers
  console.log(user)

  useEffect(() => {
    if (!portalUrl) {
      fetchPortalUrl();
    }
  }, [portalUrl]);

  const fetchPortalUrl = async () => {
    try {
      const response = await api.post('/create-customer-portal-session', {
        companyId: user.company.id
      });
      setPortalUrl(response.data.url);
    } catch (error) {
      console.error("Error fetching portal URL:", error);
    }
  };


  const handleOpenContactModal = () => {
    setSelectedContactId(user.id);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(user.id);
    setContactModalOpen(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <CheckoutModal
        open={contactModalOpen}
        handleClose={handleCloseContactModal}
        contactId={selectedContactId}
        user= {user}
      ></CheckoutModal>

      <MainHeader>
        <Title>Suscripción</Title>
      </MainHeader>
      <Grid item xs={12} sm={4}>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >

          <div>
            <TextField
              id="outlined-full-width"
              label="Fecha de vigencia de Suscripción"
              defaultValue={`¡Su suscripción termina en ${formattedDate} días!`}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />

          </div>

          <div>
            <TextField
              id="outlined-full-width"
              label="Email"
              defaultValue={user?.company?.email}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />

          </div>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-full-width"
                label="Usuarios totales"
                defaultValue={totalUsers}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-full-width"
                label="Conexiones totales"
                defaultValue={totalConnection}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenContactModal}
              fullWidth
            >
              {subscribed ? "Agregar Adicionales" : "suscribirse"}
            </Button>
          </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: "10px"}}
                onClick={() => window.location.href = portalUrl}
                fullWidth
              >
                Portal de Pagos
              </Button>
            </div>
        </Paper>
      </Grid>
    </MainContainer>
  );
};

export default Contacts;
