import React, { useState, useEffect } from "react";
import qs from 'query-string'

import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {

    Button,
    CssBaseline,
    TextField,
    Grid,
    Typography,
    Container,

    Link,
	CircularProgress
} from '@material-ui/core';
import usePlans from "../../hooks/usePlans";
import Box from "@material-ui/core/Box";
import {
	
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
import logo from "../../assets/logologin.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"


const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    height: "100vh",
    background: "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",

  },
    avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", 
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  powered: {
    color: "white"
  },
  phoneInputContainer: {
    width: "100%",
	
  },
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
	phone: Yup.string().required("Required")
});

const getBrowserLanguageId = () => {
	const language = navigator.language || navigator.userLanguage;
	if (language.startsWith('es')) {
	  return 2;
	} else if (language.startsWith('en')) {
	  return 1;
	} else {
	  return 1;
	}
  };

const SignUp = () => {
	const classes = useStyles();
	const history = useHistory();
	let companyId = null
	const [loading, setLoading] = useState(false)

	const params = qs.parse(window.location.search)
	if (params.companyId !== undefined) {
		companyId = params.companyId
	}

	const initialState = { name: "", email: "", password: "", planId: 25,phone: "" };

	const [user] = useState(initialState);
	const dueDate = moment().add(15, "day").format();
	const handleSignUp = async values => {
		Object.assign(values, { recurrence: "MENSAL" });
		Object.assign(values, { dueDate: dueDate });
		Object.assign(values, { status: "t" });
		Object.assign(values, { campaignsEnabled: true });
		Object.assign(values, { languageId: getBrowserLanguageId() });
		try {
			setLoading(true)
			console.log(values)
		await openApi.post("/companies/cadastro", values);
		toast.success(i18n.t("signup.toasts.success"));
		history.push("/login");
		setLoading(false)
		} catch (err) {
			console.log(err);
			toastError(err);
		}
	}; 

return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img style={{ margin: "0 auto", height: "80px", width: "100%" }} src={logo} alt="Whats" />
          </div>
          <Typography component="h1" variant="h5">
            {i18n.t("signup.title")}
          </Typography>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSignUp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, setFieldValue  }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										as={TextField}
										autoComplete="name"
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										fullWidth
										id="name"
										label="Nombre de la empresa"
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										id="email"
										label={i18n.t("signup.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										autoComplete="email"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										as={TextField}
										variant="outlined"
										fullWidth
										name="password"
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										label={i18n.t("signup.form.password")}
										type="password"
										id="password"
										autoComplete="current-password"
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name="phone"
										render={({ field }) => (
											<PhoneInput
											country={'us'}
											value={field.value}
											className={classes.phoneInputContainer}
											onChange={phone => setFieldValue("phone", phone)}
											inputProps={{
												name: 'phone',
												required: true,
												autoFocus: false,
												style: { 
													width: '1000%',
													border: "1px solid #ced4da",
													borderRadius: "4px"
												  }
											}}
											containerStyle={{ width: '10%', border: 'none' }}
											/>
										)}
										
									/>
										{touched.phone && errors.phone && (
										<div className="error-message">{errors.phone}</div>
									)}
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								disabled={loading}
								startIcon={loading && <CircularProgress size={20} />}
							>
								 {loading ? null : i18n.t("signup.buttons.submit")}
							</Button>
							<Grid container justify="flex-end">
								<Grid item>
									<Link
										href="#"
										variant="body2"
										component={RouterLink}
										to="/login"
									>
										{i18n.t("signup.buttons.login")}
									</Link>
								</Grid>
							</Grid>
						</Form>
					)}
				</Formik>
			</div>
			<Box mt={5}>{/* <Copyright /> */}</Box>
		</Container>
		</div>
	);
};

export default SignUp;