import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { messages } from "./languages";

i18n.use(LanguageDetector).init({
	debug: false,
	defaultNS: ["translations"],
	fallbackLng: "es",
	ns: ["translations"],
	resources: messages,
	detection: {
		order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
		lookupLocalStorage: 'i18nextLng',
		caches: ['localStorage'],
	  },  
	react: {
		useSuspense: false,
	},
});

export { i18n };